import  { useState, useEffect } from 'react';
import { Dialog, DialogTrigger, DialogContent, DialogTitle } from '@radix-ui/react-dialog';

export interface Message {
  id: string;
  content: string;
  sender: 'user' | 'bot';
  timestamp: Date;
}

export function ChatWidget({ apiKey }: { apiKey: string }) {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState('');

  useEffect(() => {
    console.log('apiKey', apiKey);
  }, [apiKey]);

  const sendMessage = (content: string) => {
    const newMessage: Message = {
      id: Date.now().toString(),
      content,
      sender: 'user',
      timestamp: new Date(),
    };
    setMessages([...messages, newMessage]);
    setInput('');
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <button className="rounded-full bg-blue-500 p-4 text-white shadow-lg">
            <ChatIcon />
          </button>
        </DialogTrigger>
        
        <DialogContent className="fixed bottom-20 right-4 w-80 rounded-lg bg-white p-4 shadow-xl">
          <div className="flex h-96 flex-col">
            <DialogTitle className="mb-4 text-lg font-bold">
              Chat Support
            </DialogTitle>
            
            <div className="flex-1 overflow-y-auto">
              {messages.map((message) => (
                <div
                  key={message.id}
                  className={`mb-2 rounded-lg p-2 ${
                    message.sender === 'user'
                      ? 'ml-auto bg-blue-500 text-white'
                      : 'bg-gray-100'
                  }`}
                >
                  {message.content}
                </div>
              ))}
            </div>
            
            <form
              onSubmit={(e) => {
                e.preventDefault();
                sendMessage(input);
              }}
              className="mt-4 flex gap-2"
            >
              <input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                className="flex-1 rounded-lg border p-2"
                placeholder="Type a message..."
              />
              <button
                type="submit"
                className="rounded-lg bg-blue-500 px-4 py-2 text-white"
              >
                Send
              </button>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function ChatIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
    </svg>
  );
}
