import React from 'react';
import { createRoot } from 'react-dom/client';
import { ChatWidget } from './components/chat-widget';
import styles from './styles/globals.css?inline';

class ChatWidgetElement extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    const mountPoint = document.createElement('div');
    this.shadowRoot?.appendChild(mountPoint);

    // Get attributes from the custom element
    const apiKey = this.getAttribute('api-key');
    
    // Create and append stylesheet
    const styleSheet = document.createElement('style');
    styleSheet.textContent = styles;
    this.shadowRoot?.appendChild(styleSheet);

    const root = createRoot(mountPoint);
    root.render(
      <React.StrictMode>
        <ChatWidget apiKey={apiKey} />
      </React.StrictMode>
    );
  }
}

customElements.define('chat-widget', ChatWidgetElement);
